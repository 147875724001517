.App {
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
  min-height: 100vh;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App a {
  text-decoration: none;
}

.App a .outer p {
  color: #666;
}

.App-header div.outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-size: calc(9px + 1.5vmin);
}

.App-header h2 {
  color: #443380;
}

.App-footer {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-self: flex-end;
  flex-grow: 2;
  justify-content: space-evenly;
  font-size: calc(9px + .4vmin);
  padding: 20px 20px 0 20px;
}

.App-footer ul {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  list-style: none;
  padding: 0;
  margin: 0;
}

.App-footer ul a {
  text-decoration: none;
  color: #443380;
}

p.App-intro {
  font-size: calc(9px + .4vmin);
  margin: 0;
  text-align: left;
}

.App-link {
  color: #61dafb;
}

.logo {
  display: flex;
  height: 100px; /*  312px */
  width: 76px; /* 237px */
}

.logo-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 98vw;
}

.logo-block p {
  font-style: italic;
}

.boxes {
	height: 33.5px;
	/* width: 62%; */
	overflow: hidden;
}

.boxes svg {
  margin: 0;
  height: 2.125vmax;
  width: 98vw;
}

.iconsquare {
  margin: 0 8px 8px 0;
}

.tech-icons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 450px;
  margin: 0 auto;
}

.tech-icons svg {
  margin: 0 8px 8px 0
}

.button-panel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-between;
  margin: 0 auto;
  margin-top: 5px;
  min-height: 33px;
  width: 96vw;
}

.edit-panel {
  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;
  -webkit-flex-wrap: no-wrap;
  justify-content: flex-start;
  align-content: space-between;
  width: 96vw;
  height: 70vh;
  overflow-y: auto;
  margin: 0 auto;
}

.edit-panel label {
  font-weight: 700;
}

.edit-panel ul {
  font-size: 90%;
  list-style: none;
  padding: 0;
  margin: 0;
}

.edit-panel li {
  cursor: pointer;
  display: inline-block;
  margin-right: 20px;
}

.edit-block {
  border: solid #97cc00 4px;
  margin: 25px 0 0 0;
  padding: 25px;
  width: 90vw;
}

.edit-block textarea {
  width: 50vw;
  height: 100px;
  resize: vertical;
}

.input-block.linear {
  display: inline;
  margin-left: 20px;
}

.input-block.linear:nth-child(1) {
  margin-left: 0;
}

.edit-error {
  margin: 20px auto;
  padding: 25px;
  text-align: center;
  width: 60vw;
}

.topic-icon {
  display: inline-block;
  margin-left: 25px;
  vertical-align: top;
}

.two-column-panel {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  width: 96vw;
  height: 65vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 auto;
}

.text-segment {
  font-size: calc(9px + 1vmin);
  line-height: calc(9px + 1.6vmin);
  min-height: 0;
  padding: 0 20px;
  width: 47%;
}

.text-segment.wide {
  width: 96vw;
}

.text-segment ul {
  /* display: inline-block; */
  margin: 0;
  vertical-align: top;
}

.text-segment ul:last-child {
  margin-bottom: 1em;;
}

.text-segment h2 {
  line-height: calc(9px + 2vmin);
}

.tooltip-item {
  background-color: #d1cc00 !important;
  border-color: #97cc00 !important;
  border-width: 2px !important;
  color: #443380 !important;
  max-width: 500px !important;
  opacity: 1 !important;
}

.tooltip-item.place-top:after {
  border-top-color: #97cc00 !important;
}

.tooltip-item.place-bottom:after {
  border-bottom-color: #97cc00 !important;
}

.tooltip-item.place-left:after {
  border-left-color: #97cc00 !important;
}

.tooltip-item a {
  color: #D2457B;
}

ul.startups {
  list-style: none;
}

ul.startups li {
  margin-top: 2px;
}

ul.startups li:nth-child(1) {
  margin-top: 0;
}

.narrow-width {
  display: none;
}

@media all and (max-width: 1178px) and (max-height: 760px) {
  .narrow-width {
    display: inline-block;
  }
  .wide-width {
    display: none;
  }
  .tall-height {
    display: block;
  }
}

@media all and (min-width: 1102px) and (min-height: 729px) {
  .narrow-width {
    display: none;
  }
  .wide-width {
    display: block;
  }
}

@media all and (min-width: 1035px) and (min-height: 761px) {
  .narrow-width {
    display: none;
  }
  .wide-width {
    display: block;
  }
}

@media all and (min-height: 708px) and (min-width: 1005px) {
  .tall-height {
    display: block;
  }
  .short-height {
    display: none;
  }
}

@media all and (max-height: 707px), (max-width: 1004px) {
  .tall-height {
    display: none;
  }
  .short-height {
    display: block;
  }
}

@media all and (max-width: 1000px) and (min-height: 1003px) {
  .tall-height {
    display: block;
  }
  .short-height {
    display: none;
  }
}

@media all and (max-width: 1000px) {
  .text-segment {
    width: 98%;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}