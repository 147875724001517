body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #4949aa;
}

button {
  font-size: calc(9px + 1vmin);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 10px;
}

button.primary {
  background-color: #4949aa;
  color: #fff;
}

button.success {
  background-color: #97cc00;
  color: #fff;
}

button.warn {
  background-color: #d1cc00;
  color: #666;
}

.error {
  background-color: #a00e17;
  border: solid 1px #d1cc00;
  color: #fff;
  font-style: italic;
  font-weight: 700;
}

.inform {
  background-color: #4949aa;
  border: solid 1px #97cc00;
  color: #d1cc00;
  font-weight: 700;
}

.show {
  opacity: 1;
  transition: opacity 1000ms;
}

.hide {
  opacity: 0;
  transition: opacity 1000ms;
}

a.button {
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

a.button.primary {
  background-color: #4949aa;
  color: #fff;
}

a.button.success {
  background-color: #97cc00;
  color: #fff;
}

a.button.warn {
  background-color: #d1cc00;
  color: #666;
}